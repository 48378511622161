/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
.not-fw .image-banner > .row {
  margin-right: 0;
  margin-left: 0; }

.image-banner .mainbanner-figure .img-link {
  display: block; }

.image-banner .image-heading-container {
  padding: 0;
  position: static; }
  .image-banner .image-heading-container .heading-inner {
    text-align: left; }
    .image-banner .image-heading-container .heading-inner.left {
      text-align: left; }
    .image-banner .image-heading-container .heading-inner.right {
      text-align: right; }
    .image-banner .image-heading-container .heading-inner.center {
      text-align: center; }
  .image-banner .image-heading-container.dark .image-heading-text {
    color: #000; }
    .image-banner .image-heading-container.dark .image-heading-text P {
      color: #000; }
  .image-banner .image-heading-container.light .image-heading-text {
    color: #fff; }
    .image-banner .image-heading-container.light .image-heading-text P {
      color: #fff; }
  .image-banner .image-heading-container.text-below {
    padding-top: 1rem; }
    .image-banner .image-heading-container.text-below .heading-inner {
      display: inline-block; }
    .image-banner .image-heading-container.text-below.left .image-heading-text {
      text-align: left; }
    .image-banner .image-heading-container.text-below.right .image-heading-text {
      text-align: right; }
    .image-banner .image-heading-container.text-below.center .image-heading-text {
      text-align: center; }
  .image-banner .image-heading-container.text-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
    .image-banner .image-heading-container.text-overlay > .container {
      position: relative;
      height: 100%;
      width: 100%; }
    .image-banner .image-heading-container.text-overlay .image-heading-text {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      text-align: left;
      margin: 0;
      max-width: 100%; }
      @media (min-width: 544px) {
        .image-banner .image-heading-container.text-overlay .image-heading-text {
          width: auto; } }
    .image-banner .image-heading-container.text-overlay.top .image-heading-text {
      top: 1rem;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      text-align: center; }
    .image-banner .image-heading-container.text-overlay.right .image-heading-text {
      right: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      text-align: right; }
    .image-banner .image-heading-container.text-overlay.bottom .image-heading-text {
      top: unset;
      bottom: 5vh;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      text-align: center; }
    .image-banner .image-heading-container.text-overlay.left .image-heading-text {
      top: 50%;
      left: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      text-align: left; }
    .image-banner .image-heading-container.text-overlay.center .image-heading-text {
      top: 50%;
      left: 50%;
      text-align: center;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .image-banner .image-heading-container.text-overlay.top.right .image-heading-text {
      top: 5vh;
      right: 0;
      left: unset;
      -webkit-transform: unset;
              transform: unset;
      text-align: right; }
    .image-banner .image-heading-container.text-overlay.top.left .image-heading-text {
      top: 5vh;
      left: 0;
      -webkit-transform: unset;
              transform: unset;
      text-align: left; }
    .image-banner .image-heading-container.text-overlay.bottom.right .image-heading-text {
      top: unset;
      bottom: 5vh;
      right: 0;
      left: unset;
      -webkit-transform: unset;
              transform: unset;
      text-align: right; }
    .image-banner .image-heading-container.text-overlay.bottom.left .image-heading-text {
      top: unset;
      bottom: 5vh;
      right: unset;
      left: 0;
      -webkit-transform: unset;
              transform: unset;
      text-align: left; }
    .image-banner .image-heading-container.text-overlay.left.center .image-heading-text {
      top: 50%;
      left: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      text-align: left; }
      @media (min-width: 544px) {
        .image-banner .image-heading-container.text-overlay.left.center .image-heading-text {
          left: 25%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%); } }
    .image-banner .image-heading-container.text-overlay.right.center .image-heading-text {
      top: 50%;
      left: unset;
      right: 0;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      text-align: right; }
      @media (min-width: 544px) {
        .image-banner .image-heading-container.text-overlay.right.center .image-heading-text {
          right: 25%;
          -webkit-transform: translate(50%, -50%);
                  transform: translate(50%, -50%);
          text-align: left; } }
    @media (max-width: 768.98px) {
      .image-banner .image-heading-container.text-overlay.mobile-top .image-heading-text, .image-banner .image-heading-container.text-overlay.mobile-top.right.bottom .image-heading-text, .image-banner .image-heading-container.text-overlay.mobile-top.right.center .image-heading-text {
        top: 0;
        bottom: unset;
        left: 0;
        right: unset;
        -webkit-transform: unset;
                transform: unset; }
      .image-banner .image-heading-container.text-overlay.mobile-bottom .image-heading-text, .image-banner .image-heading-container.text-overlay.mobile-bottom.right.top .image-heading-text, .image-banner .image-heading-container.text-overlay.mobile-bottom.right.center .image-heading-text {
        top: unset;
        bottom: 0;
        left: 0;
        right: unset;
        -webkit-transform: unset;
                transform: unset; }
      .image-banner .image-heading-container.text-overlay.mobile-center .image-heading-text {
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        bottom: unset;
        left: 0;
        right: unset; } }
